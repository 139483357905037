.file-list-card {
    width: 100%;
}

.file-dropdown-link {
    color: #ff6600;
}

.file-dropdown-link:hover {
    color: #ffaa00;
}

.folder-card:-moz-drag-over {
    border: 1px solid black;
}